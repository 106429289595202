
import { LANG } from "@/utils/language";
import WhisperAPI from "@/utils/whisper/whisperAPI";
import { scroller } from "vue-scrollto";
import FanForumDetail from "../user/FanForumDetail";
import JSBanner from "./comp/JSBanner";
import LeagueDetail from "./comp/LeagueDetail";
import NewsArticle from "./comp/NewsArticle";
import PopupBanner from "./comp/PopupBanner";
import VideoAd from "./comp/VideoAd";
import JSBannerPt from "./comp/JSBannerPt.vue";

export default {
  name: "home",
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    welcome: String,
    scrollY: Number,
  },
  data() {
    return {
      currentUser: null,
      lang: LANG,
      loading: true,
      loadingData: false,
      popupDialog: false,
      forumDetailDialog: false,
      newsCarousel: 0,
      photoCarousel: 0,
      news: [],
      newsFeatured: [],
      products: [],
      fanForumItem: {},
      ceritaBola: [],
      fans: [],
      today: new Date().getTime(),
      videoDialog: false,
      videoItem: {},
      videos: [],
      closed: false,
      photos: {},
      options: {
        centerMode: true,
        dots: false,
        slidesToShow: 3,
      },
      galleryDetailDialog: false,
      galleryDetailItem: {},
      likeArr: [],
      snackbar: false,
      snackbarWarning: false,
      snackbarText: "",
      startDate: new Date(),
      billboard: [],
      mpu: [],
      midbanner: [],
    };
  },
  components: {
    NewsArticle,
    FanForumDetail,
    LeagueDetail,
    PopupBanner,
    VideoAd,
    JSBanner,
    JSBannerPt,
  },
  watch: {
    scrollY() {
      const featured_video = document.querySelector("#featured_video");
      if (featured_video) {
        if (this.windowWidth > 770) {
          if (this.scrollY > 5000 && !this.closed) {
            document
              .querySelector("#featured_video")
              .classList.add("featuredVideo");
          } else {
            document
              .querySelector("#featured_video")
              ?.classList?.remove("featuredVideo");
          }
        } else {
          document
            .querySelector("#featured_video")
            ?.classList?.remove("featuredVideo");
        }
      }
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters.getUserInfo;
    },
    getLanguage() {
      return this.$store.state.user.language || "en";
    },
    getPlatform() {
      return this.$store.state.device.platform;
    },
    mediaOriginUrl() {
      return this.$store.state.mediaOriginUrl;
    },
    productCategories() {
      return this.$store.state.productCategories;
    },
    newsCategories() {
      return this.$store.state.newsCategories;
    },
    authUser() {
      return this.$store.getters.authUser;
    },
    socials() {
      return this.$store.state.socials;
    },
    tsponsor() {
      return this.$store.state.tsponsor;
    },
    msponsors() {
      return this.$store.state.msponsors;
    },
    osponsors_1() {
      return this.$store.state.osponsors_1;
    },
    osponsors_2() {
      return this.$store.state.osponsors_2;
    },
    osponsors_3() {
      return this.$store.state.osponsors_3;
    },
    getUser() {
      return { ...this.$store.state.user };
    },
  },
  beforeDestroy() {
    // clearInterval(this.resultInterval)
  },
  destroyed() {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    this.$store.commit("ShowNav");
    this.$store.commit("ShowNavHome");
    this.init();
  },
  methods: {
    init() {
      setTimeout(() => {
        this.popupDialog = true;
      }, 60000);
      this.getHomeBanners();
      this.loadFeaturedNews();
      this.loadNews();
      this.loadProducts();
      this.loadFans();
      this.loadVideos();
      this.loadPhotos();
    },
    getHomeBanners() {
      this.billboard = [
        WhisperAPI.createBannerJSTag(
          "010ab663-b671-1a01-8ef1-aa0d9d000b54",
          970,
          250
        ),
        WhisperAPI.createBannerJSTag(
          "010ab663-b671-1a01-8ef1-aaa415000b55",
          655,
          435
        ),
      ];
      this.mpu = [
        WhisperAPI.createBannerJSTag(
          "010ab663-b671-1a01-8ef1-ab5d47000b56",
          300,
          600
        ),
        WhisperAPI.createBannerJSTag(
          "010ab663-b671-1a01-8ef1-abd38d000b57",
          300,
          250
        ),
      ];
      this.midbanner = [
        WhisperAPI.createBannerJSTag(
          "010ab663-b671-1a01-8ef1-ac5d39000b58",
          1006,
          300
        ),
        WhisperAPI.createBannerJSTag(
          "010ab663-b671-1a01-8ef1-ada2b1000b59",
          503,
          300
        ),
      ];
    },
    loadFeaturedNews() {
      this.loadingData = true;
      this.newsFeatured = [];
      var params = {
        limit: 10,
        featured: true,
        status: 2,
      };
      Promise.resolve(this.$axios.$get("/api/news", { params }))
        .then((docs) => {
          if (docs.length > 0) {
            docs.forEach((doc) => {
              var obj = doc;
              obj.id = doc._id;
              obj.tag_league_dunia =
                obj.tag_league_dunia === undefined ? [] : obj.tag_league_dunia;
              obj.readmore = true;
              this.newsFeatured.push(obj);
            });
          }
          this.loadingData = false;
        })
        .catch((err) => {
          console.log(`Error getting documents: ${err}`);
          this.loadingData = false;
        });
    },
    loadNews() {
      this.loadingData = true;
      this.news = [];
      var params = {
        limit: 4,
        show: true,
        status: 2,
      };
      Promise.resolve(this.$axios.$get("/api/news", { params }))
        .then((docs) => {
          if (docs.length > 0) {
            docs.forEach((doc) => {
              var obj = doc;
              obj.id = doc._id;
              obj.tag_league_dunia =
                obj.tag_league_dunia === undefined ? [] : obj.tag_league_dunia;
              obj.readmore = true;
              this.news.push(obj);
            });
          }
          this.loadingData = false;
        })
        .catch((err) => {
          console.log(`Error getting documents: ${err}`);
          this.loadingData = false;
        });
    },
    openNewsArticle(item) {
      if (item.category === "banner") {
        this.readMoreClicked("news", item);
        window.open(item.news_link, "_blank");
        return;
      }
      this.$router.push("/news/detail/?slug=" + item.slug);
    },
    openNewsByCategory(item) {
      this.$router.push({ path: "/news", query: { category: item.category } });
    },
    openFanForumDetail(item) {
      history.replaceState(null, "home", "/home/discussion/" + item.slug);
      this.fanForumItem = item;
      this.forumDetailDialog = true;
      setTimeout(() => {
        this.$refs.fanForumComp.checkForum();
        this.$refs.fanForumComp.setLoadingTrue();
      }, 1000);
    },
    loadProducts() {
      const params = {
        limit: 4,
        status: 2,
      };
      Promise.resolve(this.$axios.$get("/api/products", { params }))
        .then((docs) => {
          this.products = [];
          if (docs.length > 0) {
            docs.forEach((doc) => {
              doc.price = doc.price.toString();
              // may need filtering or limiting later
              this.products.push(doc);
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(`Error getting documents: ${err}`);
          this.loading = false;
        });
    },
    loadFans() {
      var params = {
        featured: true,
        limit: 4,
        status: 2,
      };
      Promise.resolve(this.$axios.$get("/api/fanfeatures", { params }))
        .then((docs) => {
          this.fans = [];
          if (docs.length > 0) {
            docs.forEach((doc) => {
              doc.id = doc._id;
              doc.readmore = false;
              if (doc.video === "dugout") {
                let hash = doc.media.split("-");
                hash = hash[3].split('">');
                doc.embedHash = hash[0];
              }
              this.fans.push(doc);
            });
          }
        })
        .catch((err) => {
          console.log(`Error getting documents: ${err}`);
        });
    },
    loadVideos() {
      this.loading = true;
      var params = {
        featured: true,
        limit: 4,
        status: 2,
      };
      Promise.resolve(this.$axios.$get("/api/videos", { params }))
        .then((docs) => {
          if (docs.length > 0) {
            this.videos = [];
            docs.forEach((doc) => {
              doc.id = doc._id;
              doc.readmore = false;
              doc.video =
                doc.video.indexOf("youtube") > -1
                  ? "https://www.youtube.com/embed/" +
                    doc.video.split("?v=")[1] +
                    "?playsinline=1&autoplay=1&controls=1"
                  : this.mediaOriginUrl + "storage/videos/" + doc.id + ".mp4";
              this.videos.push(doc);
            });
            if (this.$route.params.slug) {
              params = {
                slug: this.$route.params.slug,
                limit: 1,
                status: 2,
              };
              Promise.resolve(this.$axios.$get("/api/videos", { params })).then(
                (data) => {
                  if (data.length > 0) {
                    data.forEach((doc) => {
                      doc.id = doc._id;
                      doc.readmore = false;
                      doc.video =
                        doc.video.indexOf("youtube") > -1
                          ? "https://www.youtube.com/embed/" +
                            doc.video.split("?v=")[1] +
                            "?playsinline=1&autoplay=1&controls=1"
                          : this.mediaOriginUrl +
                            "storage/videos/" +
                            doc.id +
                            ".mp4";
                    });
                    this.videoItem = data[0];
                    this.videoDialog = true;
                    document.getElementById("featured-video").currentTime = 0;
                  }
                }
              );
            } else {
              this.videoDialog = false;
            }
          }
          this.loadingData = false;
        })
        .catch((err) => {
          console.log(`Error getting documents: ${err}`);
          this.loadingData = false;
        });
    },
    closePopupVideo() {
      this.closed = true;
      document
        .querySelector("#featured_video")
        .classList.remove("featuredVideo");
      document.getElementById("featured-video").currentTime = 0;
    },
    openVideo(index) {
      document.getElementById("featured-video").currentTime = 0;
      this.metaTitle = this.videos[index].title;
      this.videoItem = this.videos[index];
      this.$router.replace("/videos/" + this.videos[index].slug);
      this.videoDialog = true;
      this.readMoreClicked("videos", this.videoItem);
    },
    stopVideo() {
      this.videoDialog = false;
      this.$router.replace("/");
      this.$refs.video.stopVideo();
    },
    loadPhotos() {
      const params = {
        limit: 1,
        status: 2,
      };
      Promise.resolve(this.$axios.$get("/api/photos", { params }))
        .then((docs) => {
          if (docs.length > 0) {
            docs[0].images = docs[0].images.map(
              (x) => this.mediaOriginUrl + "photos/" + x
            );
            this.photos = docs[0];
          }
        })
        .catch((err) => {
          console.log(`Error getting documents: ${err}`);
        });
    },
    galleryDetail(item) {
      this.galleryDetailItem = item;
      this.galleryDetailDialog = true;
    },
    likeThisItem(item) {
      // update the metadata etc.
      if (this.likeArr.indexOf(item.md5Hash) > -1) {
        this.snackbarText = "Too many likes...";
        this.snackbarWarning = true;
        return;
      }
      this.likeArr.push(item.md5Hash);
      // Create file metadata to update
      const likes = parseInt(item.likes) + 1;
      const dispatchObj = {
        likes: likes,
      };
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      Promise.resolve(
        this.$axios.$put(`/api/fangalleries/${item._id}`, dispatchObj, config)
      )
        .then((res) => {
          if (res) {
            item.likes = likes;
            let index = this.photos.findIndex(
              (item1) => item1._id === item._id
            );
            this.photos[index] = item;
            this.snackbarText = this.lang[this.getLanguage].FAN_GALLERY_LIKED;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          console.log(`Encountered error: ${err}`);
          this.snackbarText = err;
          this.snackbar = true;
        });
    },

    gotoAdLink(item) {
      if (item) {
        const dispatchObj = {
          clicks: (item.clicks += 1),
        };
        const config = {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        };
        Promise.resolve(
          this.$axios.$put(`/api/ads/${item.id}`, dispatchObj, config)
        )
          .then((res) => {
            if (res) {
              console.log("Item clicks in news bucket updated");
              // this.snackbarText = this.lang[this.getLanguage].AD_ARTICLE_UPDATED
              // this.snackbar = true
            }
          })
          .catch((err) => {
            console.log(`Encountered error: ${err}`);
          });
        if (item.link) {
          window.open(item.link, "_blank");
        } else if (item.button_link) {
          this.$router.push(item.button_link);
        }
      }
    },
    checkStringLength(text) {
      var stringLength;
      if (this.windowWidth < 770) {
        stringLength = 120;
      } else {
        stringLength = 155;
      }
      if (text.length < stringLength) {
        return text;
      } else {
        return text.substr(0, stringLength);
      }
    },
    readMoreClicked(which, item) {
      let dispatchObj = {
        views: (item.views += 1),
      };
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      Promise.resolve(
        this.$axios.$put(`/api/${which}/${item.id}`, dispatchObj, config)
      )
        .then((res) => {
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
        .catch((err) => {
          console.log(`Encountered error: ${err}`);
        });
    },
    gotoLink(link) {
      window.open(link, "_blank");
    },
    scrollToTop() {
      const firstScrollTo = scroller();
      setTimeout(() => {
        firstScrollTo("#home");
      }, 500);
    },
    makeDate(date) {
      return date
        ? new Date(date).toLocaleString("id-ID", { dateStyle: "long" })
        : "";
    },
    makeTime(time) {
      var hours = parseInt(time?.split(":")[0]);
      var minutes = parseInt(time?.split(":")[1]);
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    timeDifference(previous) {
      var current = Math.round(new Date() / 1000);
      var msPerMinute = 60;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;
      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerYear) + " years ago";
      }
    },
  },
};
