
export default {
  name: "jsbanner",
  props: {
    scriptCode: String,
    width: String,
    height: String,
    docId: String,
  },
  data() {
    return {
      adVisible: false,
      banners: [], // Menampung data banner dari API
      bannerImage: "/image/banner-ls.png", // Default image
      bannerUrl: "https://kitagaruda.id/news", // Default URL
    };
  },
  mounted() {
    // Fetching banner data from API
    fetch("/api/banner")
      .then((response) => response.json())
      .then((data) => {
        this.banners = data;
        if (this.banners.length > 0) {
          // Jika ada banner, gunakan data untuk bannerImage dan bannerUrl
          this.bannerImage = `https://olahbolamedia.sgp1.digitaloceanspaces.com/banner/${this.banners[0].bannerImgLS}`;
          this.bannerUrl = this.banners[0].bannerUrl;
        }
      })
      .catch((error) => {
        console.error("Error fetching banners:", error);
      });

    this.$postscribe(`#${this.docId}`, this.scriptCode);

    const observer = new MutationObserver(() => {
      if (document.querySelector(`#${this.docId} img`)) {
        this.adVisible = true;
        observer.disconnect();
      }
    });

    observer.observe(document.getElementById(this.docId), {
      childList: true,
      subtree: true,
    });
  },
  methods: {
    handleClick() {
      // Mengarahkan ke URL yang sesuai
      window.open(this.bannerUrl, "_blank");
    },
  },
};
