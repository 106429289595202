
export default {
  name: 'popupbanner',
  props: {
    windowWidth: Number,
    windowHeight: Number,
  },
  data() {
    return {
      popupBanner: [],
    }
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language || 'en'
    },
    getPlatform() {
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    },
    mediaOriginUrl() {
      return this.$store.state.mediaOriginUrl
    },
    getWeb3() {
      return this.$store.state.Web3Module
    }
  },
  mounted() {
    this.getPopupBanner()
  },
  methods: {
    async getPopupBanner () {
      try {
        const params = {
          limit: 1,
          category: 'other',
          placement: 0,
          status: 2
        }
        const data = await this.$axios.$get('/api/ads', { params })
        if (data.length > 0) {
          data.forEach(doc => {
            doc.id = doc._id
            doc.checkTime = Math.round(this.today / 1000 + 3600)
            if (doc.media.indexOf('http') === -1) {
              doc.media = this.mediaOriginUrl + 'ads/' + doc.media
            }
            if (doc.media_sm.indexOf('http') === -1) {
              doc.media_sm = this.mediaOriginUrl + 'ads/' + doc.media_sm
            }
            this.popupBanner.push(doc)
          })
        }
      } catch (error) {
        console.log(`Error getting documents: ${error}`)
      }
    },
    gotoAdLink (item) {
      if (item) {
        const dispatchObj = {
          clicks: item.clicks += 1
        }
        const config = {
          headers: {
          'Content-Type': 'application/json; charset=utf-8'
          }
        }
        Promise.resolve(this.$axios.$put(`/api/ads/${item.id}`, dispatchObj, config))
          .then(res => {
            if (res) {
              console.log("Item clicks in news bucket updated")
              // this.snackbarText = this.lang[this.getLanguage].AD_ARTICLE_UPDATED
              // this.snackbar = true
            }
          })
          .catch(err => {
            console.log(`Encountered error: ${err}`)
          })
        if (item.link) {
          window.open(item.link, '_blank')
        }
        else if (item.button_link) {
          this.$router.push(item.button_link)
        }
      }
    },
  }
}
