
import BackButton from '../web/comp/BackButton'
import GoalServeAPI from '@/utils/goalserve/goalserveAPI'
var resizebase64 = require('resize-base64')
const dateformat = require('dateformat')
export default {
  name: 'fanforumdetail',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    fanForumItem: Object
  },
  data() {
    return {
      lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      showBottomNav: false,
      signUpDialog: false,
      videoDialog: false,
      flagDialog: false,
      signUpView: 1,
      installMM: false,
      carousel: 0,
      count: 0,
      clubItem: {
        media: '',
        image: '',
        team_detail: [
          { image: '' }
        ],
      },
      currentUser: null,
      flagItem: [],
      now: dateformat(new Date().getTime(), 'dd mmm, yyyy'),
      show: false,
      resultInterval: null,
      forumComments: [],
      comment: '',
      firstReply: true,
      replyComment: '',
      replyKeepOpenId: '',
      imageDev: 'http://104.248.146.211:8080/fanforum/',
      imageProd: 'https://olahbolamedia.sgp1.digitaloceanspaces.com/fanforum/',
      valid: false,
      newsTitle: ''
    }
  },
  components: {
    BackButton
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language || 'en'
    },
    getUser() {
      return this.$store.state.user
    },
    isLoggedIn() {
      return this.$store.state.user.isLoggedIn
    },
    getPlatform() {
      return this.$store.state.device.platform
    }
  },
  beforeDestroy() {
    clearInterval(this.resultInterval)
  },
  destroyed() {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    this.$store.commit('ShowNav')
    this.$store.commit('ShowNavHome')
    this.currentUser = this.$fire.auth.currentUser
    this.init()
  },
  methods: {
    init() {
      this.getComments()
      if (this.fanForumItem.club !== '') {
        this.loadClubDetail(this.fanForumItem.club)
      }
    },
    checkForum() {
      if (this.fanForumItem.club !== '') {
        this.loading = true
        this.loadClubDetail(this.fanForumItem.club)
      }
      this.getComments()
    },
    setLoadingTrue() {
      this.loading = true
    },
    getComments() {
      this.loading = true
      var params = {
        id: this.fanForumItem.id,
      }
      Promise.resolve(this.$axios.$get('/api/fanforum', { params }))
        .then(docs => {
          this.forumComments = []
          if (docs.length > 0) {
            docs.forEach(doc => {
              var obj = doc
              obj.id = doc._id
              if (obj.status == 2) {
                obj.pid = obj.pid === undefined ? '0' : obj.pid
                if (obj.pid === '0') {
                  obj.replies = []
                  obj.first = true
                  if (this.replyKeepOpenId === obj.id) {
                    obj.open = true
                  } else {
                    obj.open = false
                  }
                }
                this.forumComments.push(obj)
                if (this.fanForumItem.club === '') {
                  this.loading = false
                }
              }
            })
            for (var r in this.forumComments) {
              let pid = this.forumComments[r].pid
              if (this.forumComments[r].pid !== '0') {
                var idx = this.forumComments.findIndex(item => item.id == pid)
                this.forumComments[idx].replies.push(this.forumComments[r])
                this.forumComments[idx].first = false
              }
            }
            this.loadingData = false
            this.loading = false
          }
          setTimeout(() => {
            this.sortArr()
          }, 2000)
        })
        .catch(err => {
          console.log(`Error getting documents: ${err}`)
          this.loadingData = false
        })
    },
    sortArr() {
      this.forumComments.sort((a, b) => {
        return b.created - a.created
      })
    },
    lookupReply(pid) {
      if (pid === '0') {
        return false
      } else {
        return true
      }
    },
    openReplies(idx) {
      this.forumComments[idx].open = !this.forumComments[idx].open
    },
    loadClubDetail(teamId) {
      Promise.resolve(GoalServeAPI.getSoccerStats(teamId))
        .then(result => {
          let team = result.data.teams.team
          this.clubItem.team_detail = team
          this.loading = false
          this.loadingData = false

        }).catch(error => {
          console.log(error)
        })
    },
    postCommentClicked(e) {
      if (this.comment === '') return
      if (e === 'click') {
        this.postForumComment()
        return
      }
      if (e.keyCode === 13 && this.comment !== '') {
        this.postForumComment()
        return
      }
    },
    postForumComment() {
      var resizedAvatar
      if (this.getUser.avatar !== '') {
        resizedAvatar = resizebase64(this.getUser.avatar, 200, 200)
      } else {
        resizedAvatar = this.getUser.avatar
      }
      var payload = {
        postkey: this.fanForumItem.id,
        comment: this.comment,
        name: this.getUser.displayName,
        uid: this.getUser.uid,
        pid: '0',
        avatar: resizedAvatar
      }
      this.$store.dispatch('MongoDBModule/postForumComment', payload)
        .then(() => {
          // this.$emit('updatedex', type)
          this.comment = ''
          this.snackbarText = 'Your Comment has been posted.'
          this.snackbar = true
          this.comment = ''
          // this.replyKeepOpenId = ''
          this.increaseCommentCount(this.fanForumItem.id)
        }).catch(error => {
          console.log(error)
          // this.addCollection = false
        })
    },
    replyCommentClicked(e, parentId, reply) {
      if (e === 'click') {
        this.replyComment = reply
        if (this.replyComment === '') return
        this.replyKeepOpenId = parentId
        this.replyForumComment(parentId)
        return
      }
      if (e.keyCode === 13) {
        this.replyComment = e.target.value
        if (this.replyComment === '') return
        this.replyKeepOpenId = e.target.id
        this.replyForumComment(e.target.id)
        return
      }
    },
    replyForumComment(parentId) {
      var resizedAvatar
      if (this.getUser.avatar !== '') {
        resizedAvatar = resizebase64(this.getUser.avatar, 200, 200)
      } else {
        resizedAvatar = this.getUser.avatar
      }
      var payload = {
        postkey: this.fanForumItem.id,
        comment: this.replyComment,
        name: this.getUser.displayName,
        uid: this.getUser.uid,
        pid: parentId,
        avatar: resizedAvatar
      }
      this.$store.dispatch('MongoDBModule/postForumComment', payload)
        .then(() => {
          // this.$emit('updatedex', type)
          this.comment = ''
          this.snackbarText = 'Your Reply has been posted.'
          this.snackbar = true
          this.replyComment = ''
          this.increaseCommentCount(this.fanForumItem.id)
        }).catch(error => {
          console.log(error)
          // this.addCollection = false
        })
    },
    increaseCommentCount(docId) {
      let obj = {
        comment_count: this.fanForumItem.comment_count + 1,
        comment_last: new Date().getTime()
      }
      this.fanForumItem.comment_count += 1
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
      Promise.resolve(this.$axios.$put(`/api/fanforums/${docId}`, dispatchObj, config))
        .then(res => {
          if (res) {
            console.log('Fan Forum comment_count in bucket updated')
            // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
            // this.snackbar = true
            this.init()
          }
        })
        .catch(err => {
          console.log(`Encountered error: ${err}`)
          this.addNewsItem = false
        })
    },
    flagComment(item) {
      this.flagItem = item
      this.flagDialog = true
    },
    processFlagging() {
      let obj = {
        status: 1,
        flagged: true,
        flagged_by: this.currentUser.uid,
        flagged_date: new Date().getTime()
      }
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
      Promise.resolve(this.$axios.$put(`/api/fanforums/${docId}/${this.flagItem.id}`, obj, config))
        .then(res => {
          if (res) {
            console.log('Fan Forum Comment in bucket updated')
            this.snackbarText = 'Comment has been flagged and temporary removed!'
            this.snackbar = true
            this.flagDialog = false
            this.init()
          }
        })
        .catch(err => {
          console.log(`Encountered error: ${err}`)
        })
    },
    newsTitleChanged() {
      var slug = this.newsTitle.toLowerCase()
      slug = slug.replaceAll(' ', '-')
      slug = slug.replaceAll('\/', '')
      slug = slug.replaceAll('?', '')
      this.newsSlug = slug
    },
    makeDate(date) {
      return date ? new Date(date).toLocaleString("id-ID", {dateStyle: 'long'}) : ''
    },
    handleSuccess(e) {
      console.log(e);
    },
    handleError(e) {
      console.log(e);
    },
  }
}
