
import BackButton from "./BackButton";
import LeagueDetail from "./LeagueDetail";
import { LANG } from "@/utils/language";

export default {
  name: "newsarticle",
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    newsItem: Object,
  },
  data() {
    return {
      metaTitle: "",
      metaDesc: "",
      metaImg: "",
      metaUrl: "",
      lang: LANG,
      loadingTopThree: false,
      topThreeCategory: [],
      imageProd: "https://olahbolamedia.sgp1.digitaloceanspaces.com/news/",
      imageProdFF:
        "https://olahbolamedia.sgp1.digitaloceanspaces.com/fanfeatures/",
    };
  },
  head() {
    return {
      title:
        this.newsItem && this.newsItem.title
          ? this.windowWidth < 770
            ? this.newsItem.title.substring(0, 40) + "..."
            : this.newsItem.title.substring(0, 60) + "..."
          : "Berita Tim Nasional Indonesia Terbaru | #Kitagaruda",
      meta: [
        {
          name: "description",
          content:
            this.newsItem && this.newsItem.story
              ? this.newsItem.story
                  .replace(/<\/?[^>]+(>|$)/g, "")
                  .substring(0, 150) + "..."
              : "Default description",
        },
        {
          property: "og:url",
          content: this.newsItem
            ? this.$config.BASE_URL + "/news/" + this.newsItem.slug
            : "",
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content:
            this.newsItem && this.newsItem.title
              ? this.windowWidth < 770
                ? this.newsItem.title.substring(0, 40) + "..."
                : this.newsItem.title.substring(0, 60) + "..."
              : "Default Title",
        },
        {
          property: "og:description",
          content:
            this.newsItem && this.newsItem.story
              ? this.newsItem.story
                  .replace(/<\/?[^>]+(>|$)/g, "")
                  .substring(0, 150) + "..."
              : "Default description",
        },
        {
          property: "og:image",
          content: this.newsItem
            ? this.imageProd + this.newsItem.id + ".png"
            : "",
        },
        {
          property: "og:image:alt",
          content:
            this.newsItem && this.newsItem.title
              ? this.windowWidth < 770
                ? this.newsItem.title.substring(0, 40) + "..."
                : this.newsItem.title.substring(0, 60) + "..."
              : "Default Title",
        },
      ],
    };
  },
  components: {
    BackButton,
    LeagueDetail,
  },
  watch: {
    windowWidth() {},
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language || "en";
    },
    getPlatform() {
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform;
    },
    newsCategories() {
      return this.$store.state.newsCategories;
    },
    getFlags() {
      return this.$store.state.flags;
    },
  },
  created() {},
  mounted() {
    this.$store.commit("ShowNav");
    this.$store.commit("ShowNavHome");
    setTimeout(() => {
      this.init();
      this.loadTopThree();
    }, 500);
  },
  methods: {
    init() {
      this.loadTopThree();
      this.loadArticleVideos();
    },
    loadTopThree() {
      this.loadingTopThree = true;
      var params = {
        limit: 3,
        tag_category: this.newsItem.tag_category,
        status: 2,
      };

      this.$axios
        .$get("/api/news", { params })
        .then((docs) => {
          this.topThreeCategory = docs.map((doc) => {
            return {
              ...doc,
              id: doc._id, // Menyalin id
              tag_league_dunia: doc.tag_league_dunia || [],
            };
          });
        })
        .catch((err) => {
          console.error("Error getting top three news:", err);
        })
        .finally(() => {
          this.loadingTopThree = false; // Set loading state false
        });
    },
    loadArticleVideos() {
      // Facebook embed Video]
      var idxFb = this.getIndicesOf(
        "<code>https://www.facebook.com/watch/",
        this.newsItem.story
      );
      // var idxFbEnd = this.getIndicesOf('</code>', this.newsItem.story) // issue here
      if (idxFb.length > 0) {
        var iCount = 0;
        for (var i in idxFb) {
          if (iCount > 0) {
            idxFb[i] = this.newsItem.story.indexOf(
              "<code>https://www.facebook.com/watch/",
              this.newsItem.story
            );
            // idxFbEnd[i] = this.newsItem.story.indexOf('</code>', this.newsItem.story)
          }
          var replaceStr = this.newsItem.story.substring(
            idxFb[i],
            idxFb[i] + 62
          ); // 6 + 49 +  // idxFbEnd[t] + 7
          var videoStr = replaceStr.replace("<code>", "");
          videoStr = videoStr.replace("</code>", "");
          var width = "100%";
          var height = this.windowWidth < 770 ? 600 : 842;
          var videoCode =
            '<div class="text-center mx-n5"><iframe src="https://www.facebook.com/plugins/video.php?href=' +
            videoStr +
            '&show_text=false&height=350&appId" width="' +
            width +
            '" height="' +
            height +
            '" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>';
          this.newsItem.story.replace(replaceStr, videoCode);
          iCount += 1;
        }
      }
      var idxTw = this.getIndicesOf(
        "<code>https://twitter.com/",
        this.newsItem.story
      );
      // var idxTwEnd = this.getIndicesOf('</code>', this.newsItem.story)
      if (idxTw.length > 0) {
        var iCountTw = 0;
        for (var t in idxTw) {
          if (iCountTw > 0) {
            idxTw[t] = this.newsItem.story.indexOf(
              "<code>https://twitter.com/",
              this.newsItem.story
            );
            // idxTwEnd[t] = this.newsItem.story.indexOf('</code>', this.newsItem.story)
          }
          var tempStrTw = this.newsItem.story.substring(
            idxTw[t],
            idxTw[t] + 80
          ); // if /XXXX/status/to long then 85
          var idxTwEnd = tempStrTw.indexOf("</code>", tempStrTw);
          var replaceStrTw = this.newsItem.story.substring(
            idxTw[t],
            idxTw[t] + idxTwEnd
          ); // idxTwEnd[t] + 7
          var videoStrTw = replaceStrTw.replace("<code>", "");
          videoStrTw = videoStrTw.replace("</code>", "");
          var widthTw = "100%";
          var heightTw = this.windowWidth < 770 ? 600 : 842;
          var videoCodeTw =
            '<div class="text-center mx-n5"><iframe border=0 frameborder=0 allowfullscreen="true" src="https://twitframe.com/show?url=' +
            videoStrTw +
            '" width="' +
            widthTw +
            '" height="' +
            heightTw +
            "></iframe></div>";
          this.newsItem.story.replace(replaceStrTw, videoCodeTw);
          iCountTw += 1;
        }
      }
      // Youtube
      var idxYt = this.getIndicesOf(
        "<code>https://youtube.com/",
        this.newsItem.story
      );
      idxYt = this.getIndicesOf(
        "<code>https://www.youtube.com/",
        this.newsItem.story
      );
      // var idxYtEnd = this.getIndicesOf('</code>', this.newsItem.story)
      if (idxYt.length > 0) {
        var iCountYt = 0;
        for (var y in idxYt) {
          if (iCountYt > 0) {
            idxYt[y] = this.newsItem.story.indexOf(
              "<code>https://youtube.com/",
              this.newsItem.story
            );
            idxYt[y] = this.newsItem.story.indexOf(
              "<code>https://www.youtube.com/",
              this.newsItem.story
            );
            // idxYtEnd[y] = this.newsItem.story.indexOf('</code>', this.newsItem.story)
          }
          var replaceStrYt = this.newsItem.story.substring(
            idxYt[y],
            idxYt[y] + 54
          ); // idxYtEnd[y] + 7
          var videoStrYt = replaceStrYt.replace("<code>", "");
          videoStrYt = videoStrYt.replace("</code>", "");
          var widthYt = "100%";
          var heightYt = this.windowWidth < 770 ? 600 : 842;
          var videoCodeYt =
            '<div class="text-center mx-n5"><iframe src="' +
            videoStrYt +
            '" :width="windowWidth > 767 ? 800 : windowWidth" width="' +
            widthYt +
            '" height="' +
            heightYt +
            '" title="Title" frameBorder="0" allowfullscreen ></iframe></div>';
          this.newsItem.story.replace(replaceStrYt, videoCodeYt);
          iCountYt += 1;
        }
      }
      // Other
      if (this.newsItem.story.search("<code>") > 0) {
        var start = this.newsItem.story.search("<code>") - 3;
        var end = this.newsItem.story.search("</code>") + 11;
        var replaceStr = this.newsItem.story.substring(start, end);
        var videoStr = replaceStr
          .replaceAll('"', "'")
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">")
          .replace("<p>", "")
          .replace("</p>", "")
          .replace("<code>", "")
          .replace("</code>", "");
        var width = "100%";
        var height = this.windowWidth < 770 ? 600 : 842;
        var videoCode =
          '<div class="text-center mx-n5"><iframe srcdoc="' +
          videoStr +
          '" width="' +
          width +
          '" height="' +
          height +
          '" style="border:none;" allowfullscreen></iframe></div>';
        this.newsItem.story = this.newsItem.story.replace(
          replaceStr,
          videoCode
        );
      }
    },
    openNewsByCategory(item) {
      this.$router.push({ path: "/news", query: { category: item.category } });
    },
    openNewsArticle(item) {
      var bucket = item.category === "fanfeature" ? "fanfeatures" : "news";
      this.readMoreClicked(item, bucket);
      this.$router.push("/news/" + item.slug);
    },
    getIndicesOf(searchStr, str, caseSensitive) {
      var searchStrLen = searchStr.length;
      if (searchStrLen == 0) {
        return [];
      }
      var startIndex = 0,
        index,
        indices = [];
      if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
      }
      while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
      }
      return indices;
    },
    checkStoryLength(story) {
      if (story.length < 200) {
        return story;
      } else {
        return story.substr(0, 200) + "...";
      }
    },
    readMoreClicked(item, bucket) {
      let dispatchObj = {
        views: (item.views += 1),
      };
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      Promise.resolve(
        this.$axios.$put(`/api/${bucket}/${item.id}`, dispatchObj, config)
      )
        .then((res) => {
          console.log("Item views in news bucket updated");
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
        .catch((err) => {
          console.log(`Encountered error: ${err}`);
        });
    },
    likeThisItem(item) {
      let dispatchObj = {
        likes: (item.likes += 1),
      };
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      Promise.resolve(
        this.$axios.$put(`/api/news/${item.id}`, dispatchObj, config)
      )
        .then((res) => {
          console.log("Item likes in news bucket updated");
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
        .catch((err) => {
          console.log(`Encountered error: ${err}`);
        });
    },
    makeDate(date) {
      return date
        ? new Date(date).toLocaleString("id-ID", { dateStyle: "long" })
        : "";
    },
    handleSuccess(e) {},
    handleError(e) {},
    timeDifference(previous) {
      var current = Math.round(new Date() / 1000);
      var msPerMinute = 60;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;
      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerYear) + " years ago";
      }
    },
  },
};
